import React,{useState,useEffect} from 'react';
import axios from 'axios';
import './App.css';

const urls  = {
                Pronostico:"http://siata.gov.co:8089/pronosticoMunicipiosSimplificado/cc77055bdd78258a6a92daa6e9de0fa0732a0a36/?format=json",
                Temperatura:"http://siata.gov.co:8089/estacionesMeteo/cc77055bdd78258a6a92daa6e9de0fa0732a0a36/",
                EstacionesAire :"http://siata.gov.co:8089/estacionesAirePM25/cc77055bdd78258a6a92daa6e9de0fa0732a0a36/",
                Test:"http://api.laf.com.co/",
                Test2:"https://siata.gov.co/EntregaData1/Datos_SIATA_Aire_co.json",
              }


const PostAsync =  async (url,callback) =>  {

  axios.get(url).catch(function (error) {
    if (error.response) {
      // Request made and server responded
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log("Error coño e su madre",error);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

  });


  // const config = {
  //       method: 'get',
  //       url: url,
  //       headers: {'Content-Type':'text/html; charset=ISO-8859-1'}
  //   }
  //
  //   let res = await axios(config)
  //
  //   console.log(res);

}

const App=()=>{

  const [pronostico, setPronostico] = useState(false);

  useEffect(() => {
    PostAsync(urls.Pronostico,callbackInit)
  },[])

  const callbackInit=(data)=>{
    console.log(data);
    //setPronostico(data.response.data)
  }


  return  <div>111</div>

}

export default App;
